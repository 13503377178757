import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, signOutThunk, useAppSelector } from "Store";
import { useMediaQuery } from "@mui/material";
import { colors, theme } from "@builderhub/mui-theme";

interface ContextValue {
  authenticated: boolean;
  scrolled: boolean;
  clickedLogo(): void;
  nav: boolean;
  name: string;
  anchorRef: React.RefObject<HTMLButtonElement> | null;
  open: boolean;
  mobileOpen: boolean;
  handleToggle(): void;
  handleClose(event: Event | React.SyntheticEvent): void;
  goMyPage(): void;
  goSignOut(): void;
  goSignIn(): void;
  goSignUp(): void;
  handleProjectRegAfterAnother(path: string): void;
  handleDrawerToggle(): void;
  top: number;
  getBackground(): string;
  getColor(): string;
  getHomeColor(): string;
  getGNBHei(): number;
}

const Context = React.createContext({} as ContextValue);

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const { authenticated, name } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [nav, setNav] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [scrolled, setScroll] = React.useState(false);
  const [top, setTop] = React.useState(0);
  const navigate = useNavigate();
  const goSignOut = () => {
    handleToggle();
    dispatch(signOutThunk());
    navigate("/");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const clickedLogo = () => {
    window.location.assign(`${process.env.REACT_APP_HOME_URL}`);
  };

  // 프로젝트 등록을 모두 마친 화면에서 프로젝트 등록 버튼을 눌렀을 때 저장 값들을 초기화 시키고 다시 섹션1으로.
  const handleProjectRegAfterAnother = (path: string) => {
    setMobileOpen(false);
    navigate(path, {
      replace: false,
      preventScrollReset: true,
      relative: "path",
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goSignIn = () => {
    window.location.assign(
      `${process.env.REACT_APP_AUTH_URL}/signin?forward=${process.env.REACT_APP_HOST_URL}`,
    );
  };

  const goSignUp = () => {
    window.location.assign(
      `${process.env.REACT_APP_AUTH_URL}/signup?forward=${process.env.REACT_APP_HOST_URL}`,
    );
  };

  const goMyPage = () => {
    window.location.assign(
      `${process.env.REACT_APP_AUTH_URL}/mypage?forward=${process.env.REACT_APP_HOST_URL}`,
    );
  };

  const getGNBHei = () => {
    if (isSm) return 48;
    if (isMd) return 52;
    return 72;
  };
  const handleScroll = React.useCallback(() => {
    const scrollTop =
      document.getElementById("mainBox")?.getBoundingClientRect().top || 0;
    setTop(scrollTop);
    if (location.pathname === "/") {
      const standard =
        document.getElementById("landingS")?.getBoundingClientRect().bottom ||
        0;
      if (standard - getGNBHei() < 0) setScroll(true);
      else setScroll(false);
    } else if (location.pathname === "/project/list") {
      const standard =
        document.getElementById("listS")?.getBoundingClientRect().top || 0;
      if (standard - getGNBHei() < 0) setScroll(true);
      else setScroll(false);
    } else if (
      location.pathname.startsWith("/project/detail") ||
      location.pathname.startsWith("/project/share/detail")
    ) {
      const standard =
        document.getElementById("DetailS")?.getBoundingClientRect().top || 0;
      if (standard - getGNBHei() < 0) setScroll(true);
      else setScroll(false);
    } else {
      const scrollTop =
        document.getElementById("mainBox")?.getBoundingClientRect().top || 0;
      if (scrollTop <= 0) setScroll(true);
      if (scrollTop > 0) setScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMd, isSm, location.pathname]);
  const getBackground = React.useCallback(() => {
    if (location.pathname === "/") {
      if (scrolled) return colors.white;
      else return `rgba(0,0,0,0.1)`;
    }
    if (top >= 0) {
      return "none";
    }
    if (
      location.pathname === "/project/list" ||
      location.pathname.startsWith("/project/detail") ||
      location.pathname.startsWith("/project/share/detail")
    ) {
      if (scrolled) return colors.white;
      else return `rgba(255, 255, 255, 0.80)`;
    }
    return colors.white;
  }, [location, scrolled, top]);
  const getColor = React.useCallback(() => {
    if (location.pathname === "/") {
      if (scrolled) {
        return colors.gray._60;
      } else return colors.white;
    } else return colors.gray._60;
  }, [location, scrolled]);
  const getHomeColor = React.useCallback(() => {
    if (location.pathname === "/") {
      if (scrolled) {
        return colors.purple._60;
      } else return colors.white;
    } else return colors.purple._60;
  }, [location.pathname, scrolled]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { capture: true }); // 스크롤 이벤트 등록
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // 스크롤 이벤트 제거
      window.removeEventListener("resize", handleScroll);
    };
  }, [isSm, isMd, handleScroll]);
  React.useEffect(() => {
    if (
      location.pathname === "/notFound" ||
      location.pathname === "/explorer"
    ) {
      setNav(false);
    } else {
      setNav(true);
    }
  }, [location.pathname]);

  return (
    <Context.Provider
      value={{
        authenticated,
        scrolled,
        clickedLogo,
        nav,
        name,
        anchorRef,
        open,
        mobileOpen,
        handleToggle,
        handleClose,
        goMyPage,
        goSignOut,
        goSignIn,
        goSignUp,
        handleProjectRegAfterAnother,
        handleDrawerToggle,
        top,
        getBackground,
        getColor,
        getHomeColor,
        getGNBHei,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLayout = () => React.useContext(Context);

export default LayoutProvider;
