class AppLogger {
  private bypass: boolean = false;

  constructor(isDebug: boolean) {
    this.bypass = isDebug;
  }

  log = (message: any) => {
    if (window.console && this.bypass) {
      console.log(message);
      return;
    } else {
      if (process.env.NODE_ENV === "production") {
        return;
      } else {
        console.log(message);
        return;
      }
    }
  };

  debug = (message: any) => {
    if (this.bypass) {
      console.log(message);
      return;
    } else {
      return;
    }
  };

  error = (message: any) => {
    if (window.console && this.bypass) {
      console.error(message);
      return;
    } else {
      if (process.env.NODE_ENV === "production") {
        return;
      } else {
        console.error(message);
        return;
      }
    }
  };
}

export const getLogger = (isDebugMode = false) => {
  const logger = new AppLogger(isDebugMode);
  return logger;
};
