export enum TypeFilterValue {
  singleHouse = "singleHouse",
  multiHouse = "multiHouse",
  urbanHouse = "urbanHouse",
  commercialHouse = "commercialHouse",
  NeighborhoodLivingFacility = "NeighborhoodLivingFacility",
  salesFacility = "salesFacility",
  accommodation = "accommodation",
  officetel = "officetel",
  generalOffice = "generalOffice",
  factory = "factory",
  storage = "storage",
  directInput = "directInput",
  unknown = "unknown",
}

export enum TypeFilterName {
  singleHouse = "단독, 다가구 주택",
  multiHouse = "연립, 다세대 주택",
  urbanHouse = "도시형 생활 주택",
  commercialHouse = "상가 주택",
  NeighborhoodLivingFacility = "근린 생활 시설",
  salesFacility = "판매 시설",
  accommodation = "숙박 시설",
  generalOffice = "일반 업무 시설",
  officetel = "오피스텔",
  factory = "공장",
  storage = "창고",
  directInput = "default",
  unknown = "unknown",
}

export const typeList = [
  {
    id: 1,
    value: TypeFilterValue.singleHouse,
    name: TypeFilterName.singleHouse,
  },
  { id: 2, value: TypeFilterValue.multiHouse, name: TypeFilterName.multiHouse },
  { id: 3, value: TypeFilterValue.urbanHouse, name: TypeFilterName.urbanHouse },
  {
    id: 4,
    value: TypeFilterValue.commercialHouse,
    name: TypeFilterName.commercialHouse,
  },
  {
    id: 5,
    value: TypeFilterValue.NeighborhoodLivingFacility,
    name: TypeFilterName.NeighborhoodLivingFacility,
  },
  {
    id: 6,
    value: TypeFilterValue.salesFacility,
    name: TypeFilterName.salesFacility,
  },
  {
    id: 7,
    value: TypeFilterValue.accommodation,
    name: TypeFilterName.accommodation,
  },
  { id: 8, value: TypeFilterValue.officetel, name: TypeFilterName.officetel },
  {
    id: 9,
    value: TypeFilterValue.generalOffice,
    name: TypeFilterName.generalOffice,
  },
  {
    id: 10,
    value: TypeFilterValue.factory,
    name: TypeFilterName.factory,
  },
  {
    id: 11,
    value: TypeFilterValue.storage,
    name: TypeFilterName.storage,
  },
  {
    id: 12,
    value: TypeFilterValue.directInput,
    name: TypeFilterName.directInput,
  },
];

export function typeNameToValue(name: TypeFilterName) {
  const result = typeList.find((item) => item.name === name);
  if (!result) return TypeFilterValue.unknown;
  return result.value;
}

export function typeValueToName(value: TypeFilterValue) {
  const result = typeList.find((item) => item.value === value);
  if (!result) return TypeFilterName.unknown;
  return result.name;
}
