import JSZip from "jszip";
import { OriginalRawData } from "Store/features/project/state";

export const fetchJson = async (jsonUrl: string) => {
  const projectUuid = jsonUrl.split("/")[1];
  if (!projectUuid) throw new Error("Wrong URL");
  const download = await fetch(
    `${process.env.REACT_APP_API_URL}/project/raw_data/${projectUuid}`,
    {
      method: "GET",
    },
  );
  const projectData = await download.json();
  const file = await fetch(projectData["presignedUrl"]);
  // const JSZip = await import("jszip");
  const zip = await JSZip.loadAsync(await file.arrayBuffer());
  const strData = await zip.file("data.json")!.async("string");
  const data = JSON.parse(strData);
  return data as OriginalRawData;
};
