import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, RawData } from "./state";
import {
  State as ListProjectsState,
  typeValueToName,
  TypeFilterValue,
} from "Context/ListFilterProvider";
import {
  DocumentType,
  ProjectStatus,
  ListOwnedProjectsQueryVariables,
  UploadOption,
} from "generated/types";
import { compact, isEmpty, omit } from "lodash";
import { fetchModelDataThunk } from "./thunks";

export type {
  DesignDocumentKeys,
  DesignDocuments,
  ProjectState,
} from "./state";

interface ServiceState {
  autocad: boolean;
  autocadCard: boolean;
  builderhub: boolean;
  builderhubCard: boolean;
  combined: boolean;
}
export const projectSlice = createSlice({
  name: "projectCreate",
  initialState,
  reducers: {
    initial: (state) => {
      state = initialState;
    },
    setService: (state, action: PayloadAction<ServiceState>) => {
      const { autocad, builderhub, combined } = action.payload;
      state.autocad = autocad;
      state.builderhub = builderhub;
      state.combined = combined;
    },
    setProject: (
      state,
      action: PayloadAction<{
        id: number;
        status: ProjectStatus;
        title: string;
        modelService: UploadOption;
        uuid: string;
      }>,
    ) => {
      const { id, status, title, modelService, uuid } = action.payload;
      state.id = id;
      state.status = status;
      state.title = title;
      state.autocad = UploadOption.Autocad === modelService;
      state.builderhub = UploadOption.Builderhub === modelService;
      state.combined = UploadOption.Combined === modelService;

      state.uuid = uuid;
    },
    setRawData: (state, action: PayloadAction<RawData>) => {
      state.rawData = action.payload;
    },
    setJsonUrl: (state, action: PayloadAction<string>) => {
      state.jsonUrl = action.payload;
    },
    // uploadedCheckBlueprint: (state, action: PayloadAction<BlueprintInfo>) => {
    //   state.designDocuments = initialState.designDocuments;
    //   action.payload.map((item) => {
    //     const { documentType, status, filename } = item;
    //     if (status === UploadStatus.Success) {
    //       state.designDocuments[documentType].isUploaded = true;
    //       state.designDocuments[documentType].file = {
    //         path: filename,
    //         name: filename,
    //         size: 0,
    //       };
    //     }
    //     return null;
    //   });
    // },
    uploadSingleComplete: (state, action: PayloadAction<DocumentType>) => {
      const documentType = action.payload;
      state.designDocuments[documentType].isUploaded = true;
    },
    // setProjectList: (state, action: PayloadAction<ListOwnedProjects>) => {
    //   // state.projectListData.list = action.payload.list;
    //   // state.projectListData.count = action.payload.count;
    // },
    setProjectListVariables: (
      state,
      action: PayloadAction<ListOwnedProjectsQueryVariables>,
    ) => {
      const variables = action.payload;
      state.projectListData.variables = variables;
    },
    // setProjectListScrollCount: (state, action: PayloadAction<number>) => {
    //   const count = action.payload;
    //   state.projectListData.scrollCount = count;
    //   state.projectListData.variables = {
    //     ...state.projectListData.variables,
    //     take: count * 10,
    //   };
    // },
    setProjectListStatusFilter: (
      state,
      action: PayloadAction<{ state: ListProjectsState }>,
    ) => {
      const { state: listProjectState } = action.payload;
      const status: Array<ProjectStatus> = [];
      if (listProjectState.popperStatus.registration) {
        status.push(ProjectStatus.RegisterComplete);
        status.push(ProjectStatus.ServiceSelectionComplete);
        status.push(ProjectStatus.UploadComplete);
      }
      if (listProjectState.popperStatus.inspection) {
        status.push(ProjectStatus.UnderInspection);
        status.push(ProjectStatus.InspectionNotPassed);
        status.push(ProjectStatus.PassInspection);
      }
      if (listProjectState.popperStatus.payment) {
        status.push(ProjectStatus.WaitingForPayment);
        status.push(ProjectStatus.WaitingForConfirmPayment);
        status.push(ProjectStatus.CompletedPayment);
      }
      if (listProjectState.popperStatus.quotation) {
        status.push(ProjectStatus.WorkingOnModeling);
        status.push(ProjectStatus.CompletedModeling);
        status.push(ProjectStatus.WorkingOnQuotation);
        status.push(ProjectStatus.CompletedQuotation);
        status.push(ProjectStatus.ConfirmationQuotation);
      }
      const statusFilter =
        !listProjectState.popperStatus.registration &&
        !listProjectState.popperStatus.inspection &&
        !listProjectState.popperStatus.payment &&
        !listProjectState.popperStatus.quotation
          ? null
          : {
              status: { in: status },
            };
      state.projectListData.variables.ownedProjectWhere = {
        ...state.projectListData.variables.ownedProjectWhere,
        ...statusFilter,
      };
    },
    setProjectListTypeFilter: (
      state,
      action: PayloadAction<{ state: ListProjectsState }>,
    ) => {
      const { state: listProjectState } = action.payload;
      const { popperType } = listProjectState;
      const containsFilters = compact(
        Object.entries(popperType).map((item) =>
          item[1]
            ? {
                constructionOption: {
                  contains: typeValueToName(item[0] as TypeFilterValue),
                },
              }
            : null,
        ),
      );
      if (isEmpty(containsFilters)) {
        state.projectListData.variables.ownedProjectWhere = omit(
          state.projectListData.variables.ownedProjectWhere,
          "OR",
        );
      }
      const filter = isEmpty(containsFilters) ? {} : { OR: containsFilters };
      state.projectListData.variables.ownedProjectWhere = {
        ...state.projectListData.variables.ownedProjectWhere,
        ...filter,
      };
    },
    startUploadFile: (state, action: PayloadAction<DocumentType>) => {
      const documentType = action.payload;
      state.designDocuments[documentType].isLoading = true;
    },
    setUploadedProject(
      state,
      action: PayloadAction<{ file: File; designKey: DocumentType }>,
    ) {
      const { file, designKey } = action.payload;
      state.designDocuments[designKey].file = file;
      state.designDocuments[designKey].isLoading = false;
    },
    deleteUploadedFile(
      state,
      action: PayloadAction<{ designKey: DocumentType }>,
    ) {
      const { designKey } = action.payload;
      state.designDocuments[designKey].file = null;
      state.designDocuments[designKey].isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelDataThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchModelDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rawData = action.payload;
      })
      .addCase(fetchModelDataThunk.rejected, (state) => {
        state.isLoading = false;
        console.log("rejected");
      });
  },
});

export const { reducer: projectReducer } = projectSlice;
export const {
  initial,
  setService,
  setProject,
  // uploadedCheckBlueprint,
  uploadSingleComplete,
  // setProjectList,
  setProjectListVariables,
  startUploadFile,
  // setProjectListScrollCount,
  setProjectListStatusFilter,
  setProjectListTypeFilter,
  setUploadedProject,
  deleteUploadedFile,
  setRawData,
  setJsonUrl,
} = projectSlice.actions;
