import React from "react";
import { getLogger } from "./logger";

export const LoggerContext = React.createContext<ReturnType<typeof getLogger>>(
  getLogger(),
);

export const LoggerDebugContext = React.createContext<
  ReturnType<typeof getLogger>
>(getLogger(true));

export const LogProvider = ({ children }: { children: React.ReactNode }) => {
  const loggerDebug = getLogger(true);
  const logger = getLogger();
  return (
    <LoggerContext.Provider value={logger}>
      <LoggerDebugContext.Provider value={loggerDebug}>
        {children}
      </LoggerDebugContext.Provider>
    </LoggerContext.Provider>
  );
};

export const useLogger = (isDebugMode: boolean) => {
  const debugLogger = React.useContext(LoggerDebugContext);
  const logger = React.useContext(LoggerContext);

  if (isDebugMode) {
    return debugLogger;
  } else {
    return logger;
  }
};
