import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
  const location = useLocation();
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (
      !window.location.href.includes("localhost") &&
      process.env.REACT_APP_TUNNEL !== "true"
    ) {
      try {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        setInit(true);
      } catch (e) {
        console.log(e);
        setInit(false);
      }
    }
  }, []);

  React.useEffect(() => {
    if (init && process.env.REACT_APP_TUNNEL !== "true") {
      try {
        ReactGA.set({ page: location.pathname });
        ReactGA.send("pageview");
      } catch (e) {
        console.log(e);
      }
    }
  }, [location, init]);
};

export default RouteChangeTracker;
