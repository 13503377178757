import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchJson } from "utils/json";
import { FetchModelDataThunkParams, RawData } from "./state";

export const fetchModelDataThunk = createAsyncThunk(
  "project/fetchModelData",
  async ({ jsonUrl }: FetchModelDataThunkParams): Promise<RawData> => {
    const data = await fetchJson(jsonUrl);
    // console.log({ fetchResult: data });
    const result = {
      ...data.entities,
      initialTotalPrice: data.initialTotalPrice,
    };
    return result as RawData;
  },
);
