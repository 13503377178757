import {
  DocumentType as BlueprintDocumentType,
  ProjectStatus,
  ListOwnedProjectsQueryVariables,
  AllModelProperty,
  BoQItem,
} from "generated/types";
import {
  ProjectListVariablesData,
  initVariables,
} from "Views/Project/List/Types";
export type DesignDocumentKeys = `${BlueprintDocumentType}`;

/* Defining the type of the value of the keys in the `DesignDocuments` object. */
export interface DocumentType {
  file: File | null | { path: string; name: string; size: number };
  isLoading: boolean;
  isError: boolean;
  isUploaded: boolean;
  message: string;
}

export enum WorkCategory {
  frame = "골조 공사",
  finish = "마감 공사",
  mechanic = "기계 공사",
  electric = "전기 공사",
  comm = "통신 공사",
  fire = "소방 공사",
  civil = "토목 공사",
  land = "조경 공사",
}

/**
 * `DesignDocuments` is an object type where the keys are the values of the `BlueprintDocumentType`
 * enum and the values are the `DocumentType` type.
 * @property {DocumentType} [: DocumentType] - DocumentType;
 */
export type DesignDocuments = {
  [key in BlueprintDocumentType]: DocumentType;
};

export type ModelData = Omit<AllModelProperty, "__typename" | "id"> & {
  BoQItem: Omit<
    BoQItem,
    "__typename" | "ProjectBoQ" | "StandardBoQ" | "_count"
  >;
};

export interface OriginalRawData {
  name: string;
  uuid: string;
  convertedAt: string;
  entities: {
    modelConc: ModelData[];
    modelRebar: ModelData[];
    modelFormwork: ModelData[];
    modelFin: ModelData[];
    modelFinBase: ModelData[];
    extra: ModelData[];
  };
  FloorNames: string[];
  RoomNames: string[];
  PartNames: string[];
  SelectedBoQItems: string[];
  initialPrices: {
    concPrice: number;
    rebarPrice: number;
    formworkPrice: number;
    finPrice: number;
    finBasePrice: number;
    extraPrice: number;
  };
  initialTotalPrice: number;
}

export type RawData = {
  modelConc: ModelData[];
  modelRebar: ModelData[];
  modelFormwork: ModelData[];
  modelFin: ModelData[];
  modelFinBase: ModelData[];
  extra: ModelData[];
  initialTotalPrice: number;
};

export interface ProjectState {
  id: number;
  title: string;
  status: ProjectStatus;
  autocad: boolean;
  builderhub: boolean;
  combined: boolean;
  isLoading: boolean;
  designDocuments: DesignDocuments;
  projectListData: ProjectListStoreData;
  uuid: string;
  jsonUrl: string;
  rawData: RawData;
}

export interface ProjectListStoreData extends ProjectListVariablesData {
  variables: ListOwnedProjectsQueryVariables;
}

export interface FetchModelDataThunkParams {
  jsonUrl: string;
}

/* Initializing the state. */
export const initialState: ProjectState = {
  id: 0,
  title: "",
  status: ProjectStatus.RegisterComplete,
  autocad: false,
  builderhub: false,
  combined: false,
  isLoading: false,
  uuid: "",
  jsonUrl: "",
  rawData: {
    modelConc: [],
    modelRebar: [],
    modelFormwork: [],
    modelFin: [],
    modelFinBase: [],
    extra: [],
    initialTotalPrice: 0,
  },
  designDocuments: {
    modeling: {
      file: null,
      isLoading: false,
      isError: false,
      isUploaded: false,
      message: "",
    },
    etc: {
      file: null,
      isLoading: false,
      isError: false,
      isUploaded: false,
      message: "",
    },
    structuralDocuments: {
      file: null,
      isLoading: false,
      isError: false,
      isUploaded: false,
      message: "",
    },
    architectureDocuments: {
      file: null,
      isLoading: false,
      isError: false,
      isUploaded: false,
      message: "",
    },
  },
  projectListData: {
    variables: initVariables,
  },
};
