import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import { Loader } from "Components";
import { LogProvider } from "Context/LogProvider";
import { BuilderhubThemeProvider, colors } from "@builderhub/mui-theme";
import { LayoutProvider } from "Context/LayoutProvider";

const ApolloProvider = React.lazy(
  () =>
    import(
      /* webpackChunkName: "apollo-client-provider" */ "../ApolloProvider/ApolloClientProvider"
    ),
);
const StoreProvider = React.lazy(
  () => import(/* webpackChunkName: "store-provider" */ "../StoreProvider"),
);
const AuthProvider = React.lazy(
  () => import(/* webpackChunkName: "auth-provider" */ "../AuthProvider"),
);

const LangProvider = React.lazy(
  () => import(/* webpackChunkName: "lang-provider" */ "../LangProvider"),
);

export function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <React.Suspense fallback={<Loader />}>
      <StoreProvider>
        <BrowserRouter>
          <ApolloProvider>
            <BuilderhubThemeProvider>
              <LayoutProvider>
                <CssBaseline />
                <SnackbarProvider
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  style={{ backgroundColor: colors.purple._60 }}
                >
                  <AuthProvider>
                    <LogProvider>
                      <LangProvider>{children}</LangProvider>
                    </LogProvider>
                  </AuthProvider>
                </SnackbarProvider>
              </LayoutProvider>
            </BuilderhubThemeProvider>
          </ApolloProvider>
        </BrowserRouter>
      </StoreProvider>
    </React.Suspense>
  );
}
