import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setCookie } from "utils";
import { errorMessageKO } from "./utils";
import {
  emailPasswordSignInThunk,
  signOutThunk,
  checkAuth,
  forgotPassword,
  emailPasswordSignUpThunk,
  emailCheck,
  getIdentityPopup,
  getIdentityRedir,
  changePassword,
  getUserIdThunk,
  // thirdpartySignInUp,
} from "./thunks";
import { initialState, IdentityParams, AuthState, Provider } from "./state";
import { IdentityGender } from "generated/types";
import { GetMyInfoData } from "./Types";

export * from "./thunks";
export { initialState as authInitialState } from "./state";
export type { AuthState, Provider };

/* Creating a slice of the Redux store. */
export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setInit: (state) => {
      state.email = initialState.email;
      state.gender = initialState.gender;
      state.identity = initialState.identity;
      state.name = initialState.name;
      state.phone = initialState.phone;
      state.isNotHaveUniqueKey = initialState.isNotHaveUniqueKey;
      state.success = initialState.success;
    },
    setAuth: (state, action: PayloadAction<{ authenticated: boolean }>) => {
      const { authenticated } = action.payload;
      state.authenticated = authenticated;
    },
    rememberUserFetch: (state, action: PayloadAction<boolean>) => {
      const rememberUser = action.payload;
      state.rememberUser = rememberUser;
      setCookie("builderhub-auth-remember", `${rememberUser}`);
    },
    setIdentity: (state, action: PayloadAction<IdentityParams>) => {
      const { identity, name, phone, birth, gender } = action.payload;
      state.identity = identity;
      state.name = name;
      state.birth = birth;
      state.gender = gender;
      state.phone = phone;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setCustomer: (state, action: PayloadAction<number>) => {
      state.customerId = action.payload;
    },
    setSignupStep: (state, action: PayloadAction<number>) => {
      state.signUpStep = action.payload;
    },
    setMyInfo: (state, action: PayloadAction<GetMyInfoData>) => {
      const {
        name,
        email,
        phone,
        organization,
        field,
        fieldName,
        customerId,
        userId,
        userHash,
        identity,
      } = action.payload;
      state.memberId = userId;
      state.memberHash = userHash;
      state.name = name;
      state.email = email;
      state.phone = phone;
      state.organization = organization;
      state.field = field;
      state.fieldName = fieldName;
      state.customerId = customerId;
      state.identity = identity;
    },
    setIdentityRedirection: (
      state,
      action: PayloadAction<{
        imp_uid: string;
        success: boolean;
      }>,
    ) => {
      const { imp_uid, success } = action.payload;
      state.impUid = imp_uid;
      state.success = success;
    },
    resetIdentityResult: (state) => {
      state.success = false;
    },
    resetError: (state) => {
      state.error = false;
      state.message = "";
    },
    thirdpartySignInUp: (
      state,
      action: PayloadAction<{
        email: string;
        id: string;
        createdNewUser: boolean;
      }>,
    ) => {
      state.email = action.payload.email;
      state.username = action.payload.id;
      state.createdNewUser = action.payload.createdNewUser;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserIdThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(getUserIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.message = "";
        state.username = action.payload;
      })
      .addCase(getUserIdThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        const {
          error: { message },
        } = response;
        state.message = errorMessageKO(message);
      })
      .addCase(emailPasswordSignInThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(emailPasswordSignInThunk.fulfilled, (state, action) => {
        const { id, email } = action.payload;
        state.loading = false;
        state.error = false;
        state.message = "";
        state.email = email;
        state.username = id;
        state.authenticated = true;
      })
      .addCase(emailPasswordSignInThunk.rejected, (state, response) => {
        const {
          error: { message },
        } = response;
        state.loading = false;
        state.error = true;
        state.message = errorMessageKO(message);
        state.authenticated = false;
      })
      // .addCase(thirdpartySignInUp.pending, (state) => {
      //   state.loading = true;
      //   state.error = false;
      //   state.message = "";
      // })
      // .addCase(thirdpartySignInUp.fulfilled, (state, action) => {
      //   const { email, id, createdNewUser } = action.payload;
      //   state.loading = false;
      //   state.error = false;
      //   state.message = "";
      //   state.authenticated = true;
      //   state.email = email;
      //   state.username = id;
      //   state.createdNewUser = createdNewUser;
      // })
      // .addCase(thirdpartySignInUp.rejected, (state, response) => {
      //   const {
      //     error: { message },
      //   } = response;
      //   state.authenticated = false;
      //   state.loading = false;
      //   state.error = true;
      //   state.message = message || "";
      // })
      .addCase(checkAuth.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        const { userId } = action.payload;
        // const { username, attributes } = currentUser;
        state.loading = false;
        state.error = false;
        state.message = "";
        // state.email = attributes["email"];
        // state.name = attributes["custom:name"];
        // state.group = attributes["custom:group"];
        // state.emailVerified = attributes["email_verified"];
        // state.poolId = currentUserInfo.id;
        if (userId) {
          state.username = userId;
          state.authenticated = true;
        } else {
          state.username = null;
          state.authenticated = false;
        }
      })
      .addCase(checkAuth.rejected, (state, response) => {
        state.loading = false;
        state.authenticated = false;
        state.message = response.error.message || "";
      })
      .addCase(signOutThunk.rejected, (state, response) => {
        // state.authenticated = false;
        state.loading = false;
      })
      .addCase(signOutThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(signOutThunk.fulfilled, (state) => {
        state.username = null;
        state.loading = false;
        state.authenticated = false;
        state.error = false;
        state.message = "";
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === "FIELD_ERROR") {
          state.forgotPassword.error.status = true;
          state.forgotPassword.error.message = "이메일 형식 오류입니다.";
        } else {
          state.forgotPassword.success.status = true;
          state.forgotPassword.success.message = "SUCCESS";
        }
      })
      .addCase(forgotPassword.rejected, (state, response) => {
        state.loading = false;
        state.forgotPassword.error.status = true;
        state.forgotPassword.error.message = response.error.message || "";
        // state.error = true;
        // state.message = response.error.message || "";
      })
      .addCase(emailPasswordSignUpThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(emailPasswordSignUpThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.message = "";
        state.email = action.payload.email;
        state.username = action.payload.id;
        state.authenticated = true;
      })
      .addCase(emailPasswordSignUpThunk.rejected, (state, response) => {
        const {
          error: { message },
        } = response;

        state.loading = false;
        state.error = true;
        state.message = errorMessageKO(message);
      })
      .addCase(emailCheck.pending, (state) => {
        state.loading = true;
        state.isEmailDuplicated = false;
        state.emailDuplicatedProviderId = null;
      })
      .addCase(
        emailCheck.fulfilled,
        (
          state,
          action: PayloadAction<{
            exists: boolean;
            thirdParty: Provider | null;
          }>,
        ) => {
          state.loading = false;
          const { exists, thirdParty } = action.payload;
          state.isEmailDuplicated = exists;
          state.emailDuplicatedProviderId = thirdParty;
        },
      )
      .addCase(emailCheck.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || "";
        state.isEmailDuplicated = false;
        state.emailDuplicatedProviderId = null;
      })
      .addCase(getIdentityPopup.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIdentityPopup.fulfilled, (state, action) => {
        state.loading = false;
        const {
          unique_key,
          name,
          phone,
          gender,
          birthday,
          email,
          isNotHaveUniqueKey,
        } = action.payload;
        state.identity = unique_key;
        state.phone = phone;
        state.name = name;
        state.birth = birthday || null;
        state.gender =
          gender === IdentityGender.Male
            ? IdentityGender.Male
            : gender === IdentityGender.Female
            ? IdentityGender.Female
            : IdentityGender.Male;
        state.success = true;
        state.email = email || "";
        state.isNotHaveUniqueKey = isNotHaveUniqueKey;
      })
      .addCase(getIdentityPopup.rejected, (state, response) => {
        state.loading = false;
        state.error = !!response.error.message;
        state.message = response.error.message || "";
        state.success = false;
      })
      .addCase(getIdentityRedir.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIdentityRedir.fulfilled, (state, action) => {
        state.loading = false;
        const {
          unique_key,
          name,
          phone,
          gender,
          birthday,
          isNotHaveUniqueKey,
          email,
        } = action.payload;
        state.identity = unique_key;
        state.phone = phone;
        state.name = name;
        state.birth = birthday || null;
        state.gender =
          gender === IdentityGender.Male
            ? IdentityGender.Male
            : gender === IdentityGender.Female
            ? IdentityGender.Female
            : IdentityGender.Male;
        state.success = true;
        state.email = email || "";
        state.isNotHaveUniqueKey = isNotHaveUniqueKey;
      })
      .addCase(getIdentityRedir.rejected, (state, response) => {
        state.loading = false;
        state.error = !!response.error.message;
        state.message = response.error.message || "";
        state.success = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = "";
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.message = "";
      })
      .addCase(changePassword.rejected, (state, response) => {
        state.loading = false;
        state.error = !!response.error.message;
        state.message = errorMessageKO(response.error.message);
      });
    // .addCase(postSignUpConfirmation.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(postSignUpConfirmation.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.isPostSignedUp = true;
    // })
    // .addCase(postSignUpConfirmation.rejected, (state, response) => {
    //   state.loading = false;
    //   state.isPostSignedUp = false;
    //   state.error = true;
    //   state.message = response.error.message || "";
    // });
  },
});

export const { reducer: authReducer } = authSlice;

export const {
  setInit,
  setAuth,
  rememberUserFetch,
  setIdentity,
  setEmail,
  setPassword,
  setCustomer,
  setSignupStep,
  setIdentityRedirection,
  resetIdentityResult,
  resetError,
  setMyInfo,
  thirdpartySignInUp,
} = authSlice.actions;
