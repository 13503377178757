import {
  SortOrder,
  FindMyManyProjectQuery,
  FindMyManyProjectQueryVariables,
} from "generated/types";
import { isEmpty } from "lodash";
import { FilterPropType } from "./Filter/Types";

export function projectLists(data: FindMyManyProjectQuery | undefined) {
  if (!data) return { totalCount: 0, filterCount: 0, list: [] };
  const { getMe, aggregateMyProject, findMyManyProject } = data;
  const myCustomerId = getMyCustomerId(getMe);
  const list = findMyManyProject.map((project) => {
    const {
      id,
      uuid,
      title,
      address: { roadAddress },
      constructionType,
      constructionOption,
      constructionEtc,
      budget,
      desiredCompletionDate,
      status,
      isFavorite,
      sharedProject,
      ownerId,
      owner,
      _count,
    } = project;
    const shareList = sharedProject.map((item) => {
      const { id, customerId, isFavorite, customer } = item;
      const name = getCustomerName(customer);
      return {
        id,
        customerId: customerId || null,
        isFavorite,
        name,
      };
    });
    const myIsFavorite = shareList.filter((item) =>
      item.customerId === myCustomerId ? item : null,
    );
    return {
      id,
      uuid,
      title,
      address: roadAddress,
      constructionOption:
        constructionType === "default"
          ? constructionEtc || ""
          : constructionOption || "",
      constructionType: constructionType,
      budget: budget || 0,
      desiredCompletionDate: desiredCompletionDate || "",
      status: status,
      isFavorite:
        myCustomerId === ownerId ? isFavorite : myIsFavorite[0].isFavorite,
      sharedCount: _count.sharedProject,
      sharedProject: shareList,
      owned: myCustomerId === ownerId,
      ownerId: ownerId || 0,
      ownerName: getCustomerName(owner),
      sharedProjectId: myCustomerId === ownerId ? 0 : myIsFavorite[0].id || 0,
    };
  });

  return {
    customerId: myCustomerId || 0,
    totalCount: getMyCustomerTotalCount(getMe),
    filterCount: getAggregateCount(aggregateMyProject),
    list,
  };
}
export type ProjectLists = ReturnType<typeof projectLists>;

const getMyCustomerId = (
  getMe:
    | {
        __typename?: "Member" | undefined;
        customer?:
          | {
              __typename?: "Customer" | undefined;
              id: number;
              projectAggregate: {
                __typename?: "AggregateProject" | undefined;
                _count?:
                  | {
                      __typename?:
                        | "ProjectCountAggregateOutputType"
                        | undefined;
                      id: number;
                    }
                  | null
                  | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined,
) => {
  if (!getMe) return 0;
  const { customer } = getMe;
  if (!customer) return 0;
  const { id } = customer;
  return id;
};

export const getAggregateCount = (
  aggregateMyProject:
    | {
        __typename?: "AggregateProject" | undefined;
        _count?:
          | {
              __typename?: "ProjectCountAggregateOutputType" | undefined;
              id: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined,
) => {
  if (!aggregateMyProject) return 0;
  const { _count } = aggregateMyProject;
  if (!_count) return 0;
  const { id } = _count;
  return id;
};

export const getMyCustomerTotalCount = (
  getMe:
    | {
        __typename?: "Member" | undefined;
        customer?:
          | {
              __typename?: "Customer" | undefined;
              id: number;
              projectAggregate: {
                __typename?: "AggregateProject" | undefined;
                _count?:
                  | {
                      __typename?:
                        | "ProjectCountAggregateOutputType"
                        | undefined;
                      id: number;
                    }
                  | null
                  | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined,
) => {
  if (!getMe) return 0;
  const { customer } = getMe;
  if (!customer) return 0;
  const { projectAggregate } = customer;
  if (!projectAggregate) return 0;
  const { _count } = projectAggregate;
  if (!_count) return 0;
  const { id } = _count;
  return id;
};

const getCustomerName = (
  customer:
    | {
        __typename?: "Customer" | undefined;
        member: {
          __typename?: "Member" | undefined;
          identity?:
            | {
                __typename?: "Identity" | undefined;
                name: string;
              }
            | null
            | undefined;
        }[];
      }
    | null
    | undefined,
) => {
  if (!customer) return "";
  const { member: members } = customer;
  if (isEmpty(members)) return "";
  const [member] = members;
  if (!member) return "";
  const { identity } = member;
  if (!identity) return "";
  const { name } = identity;
  return name || "";
};
export interface ProjectListVariablesData {
  variables: FindMyManyProjectQueryVariables;
}
export interface PropType extends FilterPropType {
  listData: ProjectLists;
  goRegist(): void;
  goDetail(val: number | string): void;
  name: string;
  customerId: number;
  page: number;
  handleSetPage(event: React.ChangeEvent<unknown>, value: number): void;
  handleShareDialogOpen(id: number): void;
  loading: boolean;
}

export interface CheckBoxPropType {
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  name: string;
}

export const defaultList = [];

export const initVariables: FindMyManyProjectQueryVariables = {
  where: {
    isDeleted: { equals: false },
  },
  skip: 0,
  take: 12,
  orderBy: { createdAt: SortOrder.Desc },
};
