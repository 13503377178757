import { isEmpty, isUndefined } from "lodash";

export function setCookie(name: string, val: string, isOneDay?: boolean) {
  const date = new Date();
  const value = val;
  const domain =
    process.env.NODE_ENV === "production" ? "builderhub.io" : "localhost";
  if (isOneDay) {
    // if it is a popup that lasts for one day, set the expiration to one day
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  } else {
    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  }
  // Set it
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;domain=${domain};`;
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (isEmpty(parts)) return null;
  if (parts.length === 2) {
    const ppop = parts.pop();
    if (isUndefined(ppop)) return null;
    return ppop.split(";").shift() || null;
  }
  return null;
}

export function deleteCookie(name: string) {
  const date = new Date();
  const domain =
    process.env.NODE_ENV === "production" ? "builderhub.io" : "localhost";
  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = `${name}=;expires=${date.toUTCString()};path=/;domain=${domain};`;
}
