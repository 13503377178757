import React from "react";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
  concat,
} from "@apollo/client";
// import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
// import { store, signOutThunk } from "Store";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Not authorized user") {
        // store.dispatch(signOutThunk());
      }
      console.error(
        `[GraphQL error]: Message: ${JSON.stringify(
          message,
        )}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
          path,
        )}`,
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function getApiDomain() {
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:8000`;
  return apiUrl;
}

export function getWebsiteDomain() {
  const websiteUrl = process.env.REACT_APP_HOST_URL || `http://localhost:3000`;
  return websiteUrl;
}

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

// const httpLink = createUploadLink({
//   uri: getApiDomain(),
//   fetch: customFetch as any,
// });

const httpLink = createHttpLink({
  uri: getApiDomain(),
  fetch: customFetch as any,
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(errorLink, httpLink),
});

export function ApolloClientProvider(props: { children: React.ReactNode }) {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloClientProvider;
