import { signOut } from "supertokens-web-js/recipe/session";
import { getCookie, setCookie } from "utils";
import { isNull } from "lodash";

setActiveTabsCount();

export async function checkRemember() {
  try {
    // Refresh 에선 새션이 존재 함, 브라우저를 종료하거나 탭을 닫은 경우 존재하지 않음
    const keep = sessionStorage.getItem("builderhub-session");
    // 사용자가 로그인 유지 체크 여부
    const rememberUser = getCookie("builderhub-auth-remember");
    // 다른 탭이 열려있는지 여부
    const tab = (() => {
      const countString = getCookie("builderhub-active-tabs-count");
      const check = Number(countString) ?? 0;
      return Number(check);
      // if (isNull(countString)) return 0;
      // return Number(countString);
    })();
    // 사용자가 로그인 유지 체크하지 않고, 세션 저장소에 세션이 존재하지 않거나, 열려있는 Active tab이 자신만 있을 경우 로그아웃
    if (rememberUser === "false" && isNull(keep) && tab <= 0) {
      await signOut();
    }
  } catch {}
}

window.addEventListener("beforeunload", beforeUnloadEventListener);

function beforeUnloadEventListener() {
  const countString = getCookie("builderhub-active-tabs-count");
  // 쿠키가 없는 경우
  if (isNull(countString)) {
    setCookie("builderhub-active-tabs-count", `0`);
    // 열려있는 탭이 0이거나 0보다 작은 경우
  } else if (Number(countString) < 0) {
    setCookie("builderhub-active-tabs-count", `0`);
  } else {
    // 그외의 경우 브라우저나 탭을 닫을 때 값을 1 감소 시킴
    setCookie("builderhub-active-tabs-count", `${Number(countString) - 1}`);
  }
}

function setActiveTabsCount() {
  const countString = getCookie("builderhub-active-tabs-count");
  // 쿠키가 없는 경우
  if (isNull(countString)) {
    setCookie("builderhub-active-tabs-count", `0`);
    // 열려있는 탭이 0이거나 0보다 작은 경우
  } else if (Number(countString) < 0) {
    setCookie("builderhub-active-tabs-count", `0`);
  }
  // 그리고 현재 접속한 브라우저 1을 증가 시킴
  setCookie("builderhub-active-tabs-count", `${Number(countString) + 1}`);
}

// window.addEventListener("beforeunload", beforeUnloadEventListener);
// /**
//  * It sets a cookie with the name "bhplf-reg-date" and the value of the current time in milliseconds.
//  */
// function beforeUnloadEventListener() {
//   setCookie("bhplf-reg-date", `${moment().valueOf()}`);
// }

// /**
//  * If the user has a cookie that says they registered more than 5 seconds ago and they didn't check the
//  * remember me box, then sign them out
//  * @returns A function that returns a promise.
//  */
// export async function checkRemember() {
//   const regDateString = getCookie("bhplf-reg-date");
//   const rememberUser = getCookie("bhplf-auth-remember");
//   if (!regDateString) return;
//   if (!rememberUser) return;
//   const regDate = moment(regDateString, "x");
//   const diff = moment().diff(regDate);
//   if (diff > 60000 && rememberUser === "false") {
//     await signOut();
//   }
// }
