import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkRemember } from "./checkRemember";
import { getIdentityAsync, getPaymentPopupAsync } from "utils";
// import { getUserId, getClaimValue } from "supertokens-web-js/recipe/session";
import { getUserId } from "supertokens-web-js/recipe/session";
// import UserRoles from "supertokens-web-js/recipe/userroles";
import {
  emailPasswordSignIn,
  emailPasswordSignUp,
  doesEmailExist,
  sendPasswordResetEmail,
  submitNewPassword,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { client } from "Context";
import { GetIdentityDataDocument, GetIdentityDataQuery } from "generated";
import { nanoid } from "nanoid";
import { RequestPayParams } from "iamport-typings";
import axios from "axios";
// import * as Sentry from "@sentry/react";

checkRemember().then(() => {
  sessionStorage.setItem(
    "builderhub-session",
    JSON.stringify({ keep: nanoid(16) }),
  );
});

export const getUserIdThunk = createAsyncThunk("auth/userId", getUserId);

export const emailPasswordSignInThunk = createAsyncThunk(
  "auth/emailPasswordSignIn",
  async ({ email, password }: { email: string; password: string }) => {
    const result = await emailPasswordSignIn({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
      ],
    });
    const { status } = result;
    if (status === "OK") return result.user;
    throw new Error(status);
  },
);

export const emailPasswordSignUpThunk = createAsyncThunk(
  "auth/emailPasswordSignUp",
  async ({
    email,
    password,
    termsEmail,
    termsSms,
    termsMarketing,
  }: {
    email: string;
    password: string;
    termsEmail: boolean;
    termsSms: boolean;
    termsMarketing: boolean;
  }) => {
    const result = await emailPasswordSignUp({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
        { id: "role", value: "CUSTOMER" },
        { id: "termsEmail", value: termsEmail.toString() },
        { id: "termsSms", value: termsSms.toString() },
        { id: "termsMarketing", value: termsMarketing.toString() },
      ],
    });
    const { status } = result;
    if (status === "OK") return result.user;
    throw new Error(status);
  },
);

// export const thirdpartySignInUp = createAsyncThunk(
//   "auth/thirdpartySignInUp",
//   async () => {
//     const response = await thirdPartySignInAndUp();
//     if (response.status !== "OK")
//       throw new Error(
//         "소셜로그인 동의항목에 이메일 항목이 없습니다. 다른 형태로 로그인 혹은 회원가입 해주세요.",
//       );
//     const {
//       createdNewUser,
//       user: { email, id },
//     } = response;
//     return { email, id, createdNewUser };
//   },
// );

export const emailCheck = createAsyncThunk("auth/emailCheck", (email: string) =>
  doesEmailExist({
    email,
  }).then((response) => {
    // response.fetchResponse.json().then(console.log);
    return response.fetchResponse.json();
  }),
);

/* Creating a thunk that will be used to reset the user's password. */
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string) => {
    return sendPasswordResetEmail({
      formFields: [{ id: "email", value: email }],
    });
  },
);

/* Creating a thunk that checks if the user is authenticated. */
export const checkAuth = createAsyncThunk("auth/check", async () => {
  const userId = await getUserId();
  // const userRole = await getClaimValue({
  //   claim: UserRoles.UserRoleClaim,
  // });
  // if (userId)
  //   Sentry.setUser({
  //     username: userId,
  //     segment: userRole ? JSON.stringify(userRole) : "undefined",
  //   });
  return { userId };
  // const sessionExist = await doesSessionExist();
  // return { sessionExist };
});

export const signOutThunk = createAsyncThunk("auth/signout", async () => {
  await axios.post(`${process.env.REACT_APP_API_URL}/auth/signout`);
  // Sentry.setUser(null);
});

/* A thunk that changes the user's password. */
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ newPassword }: { newPassword: string }) =>
    submitNewPassword({ formFields: [{ id: "password", value: newPassword }] }),
);

export const getIdentityData = async (impUid: string) => {
  const { data } = await client.query<GetIdentityDataQuery>({
    query: GetIdentityDataDocument,
    variables: { impUid },
  });
  const { certification } = data;
  if (!certification) throw new Error("No identity");
  const { response, meta } = certification;
  if (!response) throw new Error("No response from identity service");
  const { name, gender, birthday, phone, unique_key } = response;
  if (!name || !phone || !unique_key) throw new Error("Not enough identity");
  const { isNotHaveUniqueKey, email } = meta;
  return {
    name,
    gender,
    birthday,
    phone,
    unique_key,
    isNotHaveUniqueKey,
    email,
  };
};

export const getIdentityPopup = createAsyncThunk(
  "auth/identity/popup",
  async (redirectUrl: string) => {
    const iamport = await getIdentityAsync(redirectUrl);
    const { imp_uid } = iamport;
    const result = await getIdentityData(imp_uid);
    return result;
  },
);

export const getPaymentPopup = createAsyncThunk(
  "auth/payment/popup",
  async (pay_data: RequestPayParams) => {
    const iamport = await getPaymentPopupAsync(pay_data);
    return iamport;
  },
);

export const getIdentityRedir = createAsyncThunk(
  "auth/identity/redir",
  getIdentityData,
);
