import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetForgeTokenDocument,
  GetForgeTokenQuery,
  ForgeAuthScope,
} from "generated";
import { client } from "Context";

export const forgeViewerInitializeThunk = createAsyncThunk(
  "forge/viewerInit",
  forgeInitialize,
);

/**
 * It gets the current user's session, gets the JWT token from the session, creates a GraphQL client
 * with the JWT token as the authorization header, gets the Forge SDK from the client, and then gets
 * the Forge token from the SDK
 * @returns The token is being returned.
 */
async function getForgeTokenData() {
  const { data } = await client.query<GetForgeTokenQuery>({
    query: GetForgeTokenDocument,
    variables: { scopes: [ForgeAuthScope.DataRead] },
  });
  const { forge } = data;
  if (!forge) throw new Error("No authorized");
  const { authentication } = forge;
  if (!authentication) throw new Error("No authorized");
  const { getForgeToken } = authentication;
  if (!getForgeToken) throw new Error("No authorized");
  return getForgeToken;
}

interface ForgeInitializerParams {
  el: HTMLDivElement;
  urn: string;
}

interface ForgeIntializerResolvedValue {
  /**
   * The Autodesk.Viewing.Document loaded document
   */
  doc: Autodesk.Viewing.Document;
  /**
   * The Forge token data
   */
  tokenData: {
    __typename?: "ForgeToken" | undefined;
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  /**
   * Forge Viewer
   */
  viewer: Autodesk.Viewing.GuiViewer3D;
}

/**
 * It initializes the Forge viewer, loads the model, and returns the viewer, the model, and the Forge
 * token data
 * @param {ForgeInitializerParams}  - `el` - the DOM element where the viewer will be rendered
 * @returns An object with the following properties:
 * - doc: The loaded document
 * - tokenData: The token data
 * - viewer: The viewer
 */
async function forgeInitialize({
  el,
  urn,
}: ForgeInitializerParams): Promise<ForgeIntializerResolvedValue> {
  const tokenData = await getForgeTokenData();
  const options: Autodesk.Viewing.InitializerOptions = {
    env: "AutodeskProduction",
    api: "derivativeV2",
    getAccessToken(callback?) {
      getForgeTokenData().then((token) => {
        const { access_token, expires_in } = token;
        if (callback) {
          callback(access_token, expires_in);
        }
      });
    },
  };
  const viewer = await forgeViewerInit(options, el, urn);
  viewer.start();
  const doc = await forgeViewrLoad(urn);
  const defaultModel = doc.getRoot().getDefaultGeometry();
  viewer.loadDocumentNode(doc, defaultModel);
  return { doc, tokenData, viewer };
}

/**
 * It returns a promise that resolves to a viewer object
 * @param options - Autodesk.Viewing.InitializerOptions
 * @param {HTMLDivElement} el - HTMLDivElement - the div element that will contain the viewer
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a viewer object.
 */
async function forgeViewerInit(
  options: Autodesk.Viewing.InitializerOptions,
  el: HTMLDivElement,
  urn: string,
): Promise<Autodesk.Viewing.GuiViewer3D> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Initializer(options, async function onInitialized() {
      const viewer = new Autodesk.Viewing.GuiViewer3D(el);
      await viewer.loadExtension("Autodesk.Viewing.Popout");
      // const popoutExtension = viewer.getExtension("Autodesk.Viewing.Popout");
      await viewer.loadExtension("Autodesk.ModelStructure");
      resolve(viewer);
    });
  });
}

/**
 * It loads a Forge model from a URN and returns a promise that resolves to a Forge document
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a document object.
 */
async function forgeViewrLoad(urn: string): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      urn,
      function onDocumentLoadSuccess(doc) {
        resolve(doc);
      },
      function onDocumentLoadFailure(error) {
        console.log({ error });
        reject(error);
      },
    );
  });
}
