import React from "react";
// import ReactDOM from "react-dom/client"; // moved to dynamic import
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { RootProvider } from "Context";
// import * as Sentry from "@sentry/react"; // moved to dynamic import
// import pjson from "../package.json";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from "react-router-dom";

// import("@sentry/react").then((Sentry) =>
//   Sentry.init({
//     release: "builderhub-platform-customer@" + pjson.version,
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     tracesSampleRate: 1.0,
//     integrations: [
//       new Sentry.BrowserTracing({
//         tracePropagationTargets: ["localhost", /builderhub\.io/, /^\//],
//         shouldCreateSpanForRequest: (url) => {
//           return !url.match(/\/health\/?$/);
//         },
//         routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//           React.useEffect,
//           useLocation,
//           useNavigationType,
//           createRoutesFromChildren,
//           matchRoutes,
//         ),
//       }),
//       new Sentry.Replay({
//         maskAllInputs: false,
//         maskAllText: false,
//         blockAllMedia: true,
//       }),
//     ],
//     replaysOnErrorSampleRate: 1.0,
//     replaysSessionSampleRate: 0.3,
//     enableTracing: true,
//     environment:
//       process.env.NODE_ENV === "production"
//         ? process.env.REACT_APP_ENV
//         : "localhost",
//   }),
// );

function Root() {
  return (
    // <React.StrictMode>
    <RootProvider>
      <App />
    </RootProvider>
    // </React.StrictMode>
  );
}
// tracerInit();
import("react-dom/client").then((ReactDOM) => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );
  root.render(<Root />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
