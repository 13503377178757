import { configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import { authReducer, projectSlice, forgeSlice } from "./features";
export * from "./features";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customMiddleware: Middleware = (store) => (next) => (action) => {
  return next(action);
};

const middlewares = (): Middleware[] => {
  if (process.env.NODE_ENV !== "production") {
    return [logger, customMiddleware];
  } else {
    return [customMiddleware];
  }
};

/* Creating a store with the reducer and middleware. */
export const store = configureStore({
  reducer: {
    auth: authReducer,
    // [apiSlice.reducerPath]: apiSlice.reducer,
    project: projectSlice.reducer,
    forge: forgeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares()),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/* Exporting the useDispatch hook from react-redux and giving it a type. */
export const useAppDispatch: () => AppDispatch = useDispatch;
/* Exporting the useSelector hook from react-redux and giving it a type. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
